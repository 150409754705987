@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    @apply text-off-black;
    
    font-family: 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* body {
  margin: 0;
  font-family: 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: 'Lato', sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.bubble {
  border-radius: 50%;
  position: relative;
  -webkit-animation-name: float;
  -webkit-animation-count: infinite;
  animation-name: float;
  animation-iteration-count: infinite;
  box-shadow: 0px -2px #FFFFFF; 
}

@-webkit-keyframes float {
  0% {
    background: #2E83AF;
    top: 100%; 
  }
  100% {
    background: #81FFE4;
    top: -5%; 
  } 
}
@keyframes float {
  0% {
    background: #2E83AF;
    top: 100%; 
  }
  100% {
    background: #81FFE4;
    top: -5%; 
  } 
}